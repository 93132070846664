import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const msalConfig: Configuration = {
  auth: {
    // Application (client) ID from Azure
    authority:
      "https://login.microsoftonline.com/811d1548-f8ce-46e5-b006-889088740cba",
    clientId: "4b6b7ca5-5774-4f9c-acf3-08250e9a489c", // Directory (tenant) ID
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI, // Redirect URI configured in Azure
  },
  cache: {
    cacheLocation: "sessionStorage", // Default is localStorage
    storeAuthStateInCookie: false, // Set this to true if you have issues on IE11 or Edge
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
