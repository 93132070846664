import React from "react";

import { MsalProvider } from "@azure/msal-react";
import { ConfigProvider } from "antd";
import locale from "antd/locale/en_GB";
import { createRoot } from "react-dom/client";
import "./index.css";

import msalInstance from "authConfig";
import CustomConfigProvider from "components/CustomConfigProvider/CustomConfigProvider";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <CustomConfigProvider>
        <App />
      </CustomConfigProvider>
    </MsalProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
