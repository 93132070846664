import React from "react";

import "./App.css";

import "./i18n";
import Routing from "screens/Routing";

const App = () => {
  return <Routing />;
};

export default App;
