import React from "react";

import {
  InteractionRequiredAuthError,
  PopupRequest,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Button, Space, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import bgLogo from "assets/images/loginPage/logo.svg";
import {
  Background,
  FactContainer,
  FactText,
  HeaderText,
  PlainText,
  SmallText,
  StyledCountDown,
  SubLogInSpace,
  SubtitleText,
  TimerContainer,
} from "screens/LoginPage/styled";
const loginRequest: PopupRequest = {
  scopes: ["api://4b6b7ca5-5774-4f9c-acf3-08250e9a489c/BeeTackScope"],
};

const LoginPage: React.FC = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch((e) => {
      if (e instanceof InteractionRequiredAuthError) {
        // Fallback to a different method of authentication
      } else {
        console.error(e);
      }
    });
  };

  return (
    <Background>
      <img src={bgLogo} alt="Logo Bee-Tack" style={{ alignSelf: "center" }} />
      <HeaderText>Dont let your hours buzz by unfilled!</HeaderText>
      <TimerContainer>
        <Space
          style={{ alignItems: "start", flexDirection: "column", width: "35%" }}
        >
          <Typography.Title level={2}>
            Time Flies! Hurry up before you get stung.
          </Typography.Title>
          <Button
            type="primary"
            style={{ boxShadow: "none" }}
            onClick={handleLogin}
          >
            Log In
          </Button>
        </Space>
        <StyledCountDown
          renderMap={[true, true, true, false]}
          showSeparators={false}
          labelStyle={{ color: "white", fontSize: 30, fontWeight: "bold" }}
          to={dayjs().add(1, "month").startOf("month").valueOf()}
        />
      </TimerContainer>
      <Space>
        <FactContainer>
          <FactText>FUN FACT</FactText>
          <PlainText>
            6 out of 10 people believe developers are watching p*rn at least 4
            hours during their work shift.
          </PlainText>
        </FactContainer>
        <SubLogInSpace>
          <SubtitleText level={3}>
            Our mission is to prove people are wrong!
          </SubtitleText>
          <SmallText>
            We can’t do this without you! Track your time and become part of
            something bigger!
          </SmallText>
          <Button
            type="primary"
            style={{ boxShadow: "none" }}
            onClick={handleLogin}
          >
            Log In
          </Button>
        </SubLogInSpace>
      </Space>
    </Background>
  );
};

export default LoginPage;
