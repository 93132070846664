export const COLORS = {
  accent1Primary: "#7DE480",
  accent1Quoternary: "#EBF88D",
  accent1Secondary: "#E0F162",
  accent1Tertiary: "#E7EBC9",
  accentSecondary: "#36CDBA",
  backgroundPrimary: "#231F20",
  bgBox: "#34D1BD",
  bgDarken: "#F8F8F8",
  bgLight: "#FFFFFF",
  borderPrimary: "#88B2AB",
  buttonPrimary: "#A7E272",
  clickState: "#0CB296",
  disabledBackground: "#EFEFEF",
  disabledText: "#8A8A8A",
  hoveredState: "#69D8A9",
  icons: "#7C7B7B",
  stroke: "#D7D7D7",
  successful: "#7ED26F",
  textPrimary: "#181818",
  textSecondary: "#5C5C5C",
  textSubtitle: "#CCE804",
  textTertiary: "#747474",
};
