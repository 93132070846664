import { FC } from "react";

import { Button, Flex } from "antd";
import { useNavigate } from "react-router-dom";

const DashboardPage: FC = () => {
  const navigate = useNavigate();

  return (
    <Flex vertical>
      dashboard!
      <Button
        type="primary"
        style={{ width: 500 }}
        onClick={() => navigate("/login")}
      >
        BACK TO LOGIN
      </Button>
    </Flex>
  );
};
export default DashboardPage;
