import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import { Flex, Layout, Space, Typography } from "antd";
import styled from "styled-components";

import backGroundImage from "assets/images/loginPage/backgroundImage.svg";
import { COLORS } from "components/utils/colors";

export const Background = styled(Layout)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: ${COLORS.backgroundPrimary};
  padding: 70px 120px;
  background-image: url(${backGroundImage});
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
`;

export const HeaderText = styled(Typography.Title)`
  &.ant-typography {
    color: ${COLORS.bgDarken};
  }
  width: 25%;
`;

export const TimerContainer = styled(Flex)`
  background-color: ${COLORS.bgBox};
  justify-content: space-between;
  border-radius: 16px;
  padding: 50px 60px;
  margin: 50px 0;
`;

export const FactContainer = styled(Space)`
  flex-direction: column;
  align-items: start;
  padding: 30px 40px;
  width: 80%;
  background:
    linear-gradient(
        to right,
        ${COLORS.backgroundPrimary} 33%,
        ${COLORS.borderPrimary} 0%
      )
      top/20px 2px repeat-x,
    linear-gradient(${COLORS.backgroundPrimary} 33%, ${COLORS.borderPrimary} 0%)
      right/2px 20px repeat-y,
    linear-gradient(
        to right,
        ${COLORS.backgroundPrimary} 33%,
        ${COLORS.borderPrimary} 0%
      )
      bottom/20px 2px repeat-x,
    linear-gradient(${COLORS.backgroundPrimary} 33%, ${COLORS.borderPrimary} 0%)
      left/2px 20px repeat-y;
  border-radius: 16px;
`;

export const FactText = styled(Typography.Text)`
  color: ${COLORS.textSubtitle};
`;

export const PlainText = styled(Typography.Text)`
  color: ${COLORS.bgLight};
  font-size: 36px;
`;

export const SubLogInSpace = styled(Space)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
`;

export const SubtitleText = styled(Typography.Title)`
  &.ant-typography {
    color: ${COLORS.bgLight};
  }
`;

export const SmallText = styled(Typography.Text)`
  &.ant-typography {
    color: ${COLORS.bgLight};
  }
  font-size: 16px;
`;
export const StyledCountDown = styled(FlipClockCountdown)`
  &.fcc__unit_time--second {
    display: none !important;
  }
`;
