import { FC } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import AllRoutes from "screens/AllRoutes";
import DashboardPage from "screens/DashboardPage";
import LoginPage from "screens/LoginPage/LoginPage";

const Routing: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={AllRoutes.dashboard} element={<DashboardPage />} />
        <Route path={AllRoutes.login} element={<LoginPage />} />
        <Route path={"/"} element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
