import { FC, PropsWithChildren } from "react";

import { ConfigProvider } from "antd";
import locale from "antd/locale/en_GB";
import { locale as dayjsLocale } from "dayjs";
import "dayjs/locale/en-gb";

dayjsLocale("en-GB");

const CustomConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          borderRadius: 2,
          colorBgContainer: "#181818",
          colorPrimary: "#A7E272",
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
export default CustomConfigProvider;
